var Theme;

jQuery(document).ready(function () {

	Theme = {

		/**
		 * Init
		 */
		init: function () {

			// Cache elements
			Theme.w = jQuery(window);
			Theme.html = jQuery('html');
			Theme.body = jQuery('body');
			Theme.visual = jQuery('.visual');
			Theme.parallax = jQuery('[data-parallax]');

			Theme.toggleNavButton = jQuery('.toggle-nav');
			Theme.toggleSubNavButton = jQuery('.menu-item-has-children > a');

			Theme.slider = jQuery('.slider');
			Theme.slides = Theme.slider.find('.slide');
			Theme.sliderPrev = jQuery('.slide-prev');
			Theme.sliderNext = jQuery('.slide-next');

			Theme.searchField = jQuery('#s');
			Theme.searchSubmitButton = jQuery('#search-submit');
			Theme.searchToggleButton = jQuery('.toggle-search');

			Theme.accordionToggle = jQuery('.accordion__title');

			Theme.mobileWidth = 800;

			// Go!
			Theme.bindings();

		},

		bindings: function () {

			// Lightbox
			jQuery('a[href$=".jpg"],a[href$=".jepg"],a[href$=".png"]').featherlight();

			// Sticky sidebar
			jQuery('.fixedsticky').stick_in_parent();

			// Slider
			if (1 < Theme.slides.length) {
				Theme.slider.slick({
					arrows: true,
					dots: true,
					fade: true,
				});
			}

			// Toggle nav
			Theme.toggleNavButton.click(function (e) {
				e.preventDefault();
				Theme.toggleNav();
			});

			// Toggle subnav
			Theme.toggleSubNavButton.click(function (e) {
				if (Theme.mobileWidth < Theme.w.width())
					return;

				e.preventDefault();

				jQuery(this).next().slideToggle();
			});

			// Toggle search
			Theme.searchToggleButton.click(function (e) {
				e.preventDefault();
				Theme.toggleSearch();
			});

			Theme.searchField.focus(function () {
				jQuery(this).addClass('show-search');
			});

			Theme.searchSubmitButton.click(function (e) {
				if ('' != Theme.searchField.val())
					return;

				e.preventDefault();
				Theme.toggleSearch();
			});

			// Accordion
			Theme.accordionToggle.click(function (e) {
				e.preventDefault();
				Theme.toggleAccordion(jQuery(this));
			})

			// Visual
			Theme.parallax.each(function (i, e) {
				var obj = jQuery(this);
				if (obj.data('parallax'))
					obj.parallax({
						imageSrc: obj.data('parallax')
					});
			});

		},

		submitForm: function (form, options) {

			if (Theme.validateForm(form) && (undefined === options || undefined === options.validate || (options && typeof options.validate === 'function' && true === options.validate()))) {

				if (options && typeof options.before === 'function')
					options.before();

				form.addClass('submitting');
				var button = form.find('button[type=submit]').addClass('loading');
				var data = form.serialize();

				jQuery.post(ajaxurl, data, function (response) {

					button.removeClass('loading');

					if (undefined !== options && false !== options.empty) {
						form.find('input[type="text"],textarea,select').val('');
						form.find('input[type="radio"], input[type="checkbox"]').removeAttr('checked');
					}

					if (options && typeof options.response === 'function')
						options.response(form, response);
					else
						form.before(response);

					if (options && typeof options.after === 'function')
						options.after(response);

					form.removeClass('submitting');

				});

			}

		},

		toggleAccordion: function (obj) {

			var container = obj.parents('.accordion'),
				content = container.find('.accordion__content');

			content.slideToggle();
			container.toggleClass('accordion--closed');
			container.toggleClass('accordion--opened');
		},

		toggleNav: function () {

			Theme.body.toggleClass('nav-visible');

		},

		toggleSearch: function () {

			Theme.body.toggleClass('search-visible');

			if (Theme.body.hasClass('search-visible'))
				Theme.searchField.focus();

		},

		validateForm: function (form) {

			if (form.hasClass('submitting'))
				return false;

			// Remove old validation
			jQuery('.error-input').removeClass('error-input');
			form.find('.message').remove();

			// Cache
			var submit = true,
				requiredFields = form.find('input.required, textarea.required');

			// Validate the fields
			requiredFields.each(function (i, e) {
				var obj = jQuery(this);
				if ('' === obj.val()) {
					obj.addClass('error-input');
					submit = false;
				}
			});

			return submit;
		}

	};

	Theme.init();

});
